import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PostCard from './PostCard';
import Loading from './Loading';
import { useRealm } from '../RealmProvider';

function Posts() {
  const { user } = useRealm();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const mongo = user.mongoClient("mongodb-atlas");
      const collection = mongo.db("production").collection("posts");
      const posts = await collection.find({});
      console.log(posts);
      setPosts(posts);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchPosts();
    }
  }, [user]);

  return (
    <Container maxWidth="lg">
      {loading ? (
        <Loading />
      ) : (
        <Grid container spacing={1} justifyContent="center">
          {posts?.map((post) => (
            <PostCard key={post._id} data={post} />
          ))}
        </Grid>
      )}
    </Container>
  );
}

export default Posts;
