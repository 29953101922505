import React from 'react';
import { Typography, List, ListItem, ListItemText, Container, Box, Grid, Card, CardContent } from '@mui/material';
import Loading from '../components/Loading';

const products =[
  {
    name:"Splash",
    logo:"https://intechgroup.blob.core.windows.net/intechgroup/spash.jpeg",
    description:`Splash is an advanced beauty and fashion
    platform designed to revolutionize the beauty industry. With a focus on empowering service
    providers and enhancing the client experience, Splash offers a comprehensive suite of
    innovative features tailored to meet the diverse needs of beauticians, salon owners,
    fashion designers, and product sellers.`
  },
  {
    name:"MusicLit",
    logo:"https://intechgroup.blob.core.windows.net/intechgroup/music.jpeg",
    description:`MusicLit is an advanced tech music platform
    designed to empower musicians, producers, writers, composers, record labels,
    recording studios, music video creators, podcasters, event organizers, MCs, and DJs.
    With a focus on innovation and user experience, Music Lit offers a comprehensive suite
    of features tailored to meet the diverse needs of the music industry.`
  },
  {
    name:"Theatre Space",
    logo:"https://intechgroup.blob.core.windows.net/intechgroup/theatre.jpeg",
    description:`Theatre Space is an innovative platform
    designed to revolutionize the theater industry. Theatre Space provides a digital stage for
    actors to showcase their talent, allows theater shows to reach a wider audience, and
    facilitates ticket sales for live and virtual performances`
  }
]

const Info = () => {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <Container sx={{ mt: 15 }}>
      <Typography variant="h5" gutterBottom color="primary" textAlign="center" sx={{ fontWeight: 'bold', mb: 4 }}>
        Welcome to Our Service Providers Portal
      </Typography>
      <Typography variant="body1" paragraph sx={{ textAlign: 'center', mb: 4 }}>
        Our portal connects service providers with potential clients across various domains. Whether you offer beauty services, sell products, perform music, or work in acting, this platform is designed to help you showcase your talents and connect with your audience.
      </Typography>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" fontWeight={800} color="primary" sx={{ mb: 2 }}>Categories</Typography>
        <List>
          <ListItem sx={{ mb: 2 }}>
            <ListItemText primary="Beauty" secondary="Makeup artists, hairstylists, nail technicians, etc." />
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <ListItemText primary="Product Selling" secondary="Handmade crafts, fashion items, gadgets, etc." />
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <ListItemText primary="Acting" secondary="Actors, actresses, performers, etc." />
          </ListItem>
          <ListItem sx={{ mb: 2 }}>
            <ListItemText primary="Music" secondary="Musicians, bands, DJs, etc." />
          </ListItem>
        </List>
      </Box>

      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" fontWeight={800} color="primary" sx={{ mb: 2 }}>Products</Typography>
        <Grid container spacing={3}>
            {products?.map((product)=>(
          <Grid item xs={12} sm={6} md={4}>
            <Card sx={{ boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.1)', mb:2 }}>
              <CardContent>
                <img src={product.logo} onLoad={handleImageLoad} alt="Logo 1" style={{ width: '30%', height: 'auto', borderRadius: '8px', margin: '0 auto', display: 'block', mb: '8px',marginBottom:"15px" }} />
                <Typography variant="body1" paragraph color="text.primary" sx={{ textAlign: 'center' }}>
                {product.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
            ))}
        </Grid>
      </Box>

      <Typography variant="body1" paragraph sx={{ textAlign: 'center', mt: 6 }}>
        Join our community of service providers today and let your skills shine!
      </Typography>
    </Container>
  );
};

export default Info;