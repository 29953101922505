import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import { Button, FormControl, Grid, InputLabel } from '@mui/material';
import { ArrowOutward, Delete, Edit } from '@mui/icons-material';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Loading from './Loading';
import { useNavigate } from 'react-router-dom';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
export default function PostCard({ admin, data, onDelete, onEdit }) {
  const [open, setOpen] = React.useState(false);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [imageUrl, setImageUrl] = React.useState(null);
  const [description, setDescription] = React.useState(data.description);
  const postImage = `${data.image}`
  const [expanded, setExpanded] = React.useState(false);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const navigate = useNavigate();
  
  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImageUrl("");
  };

  const handleFileSelect = (files) => {
    const file = files[0];
    setSelectedFile(file);

    // Read the selected file and display it
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleEdit = () => {
    // Call the onEdit function passed from the parent component
    onEdit(data._id, { description, imageUrl });
    handleClose();
  };

  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this post?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => onDelete(data._id)
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  };
    // Finding the index of the first occurrence of "." or "?"
    const periodIndex = data.description.indexOf(".");
    const questionIndex = data.description.indexOf("?");

    // Finding the index of the end of the first sentence
    const firstSentenceEndIndex = Math.min(periodIndex !== -1 ? periodIndex : Infinity, questionIndex !== -1 ? questionIndex : Infinity);

    // Extracting heading and body
    const heading = data.description.substring(0, firstSentenceEndIndex + 1); // Including the period or question mark
    const body = data.description.substring(firstSentenceEndIndex + 2);


  return (
    <Grid item xs={12} sm={4}>
      <Card sx={{ bgcolor:'inherit',boxShadow:3 }} className="cardAnimation">
        <CardMedia
          component="img"
          image={postImage}
          onLoad={handleImageLoad}
          alt="Post image"
          sx={{ objectFit: "fill",aspectRatio:"1.4", display: imageLoaded ? 'block' : 'none' }}
        />
        {!imageLoaded && <div><Loading/></div>}
        <CardContent>
          <Typography variant="body2" fontWeight={600} color="text.secondary">
          {heading}
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          {admin && (
            <>
              <Button size='small' variant='contained' onClick={confirmDelete} color='error' sx={{ m:.2 }} startIcon={<Delete/>}>Delete</Button>
              <Button size='small' variant='contained' startIcon={<Edit/>} sx={{ m:.2 }} onClick={handleClickOpen} color='success'>Edit</Button>
            </>
          )}
          {!admin && (
            <>
              <Button
              size='small'
                onClick={()=>navigate('/SignUp')}
                sx={{
                  m: 0.2,
                  variant: 'outlined',
                  color: 'purple',
                  border: '2px solid',
                  borderColor: 'purple',
                  borderRadius: '20px',
                  padding: '10px 20px',
                  position: 'relative',
                  animation: 'pulse 2s infinite',
                  '@keyframes pulse': {
                    '0%': { borderColor: 'purple', boxShadow: '0 0 0 0 rgba(0,0,0,0.7)' },
                    '50%': { borderColor: 'rgba(155, 89, 182, 0)', boxShadow: '0 0 0 2px rgba(155, 89, 182, 0)' },
                    '100%': { borderColor: 'purple', boxShadow: '0 0 0 0 rgba(0,0,0,0)' },
                  },
                  '&:hover': {
                    animation: 'bounce 0.5s',
                  },
                  '@keyframes bounce': {
                    '0%': { transform: 'translateY(0)' },
                    '50%': { transform: 'translateY(-10px)' },
                    '100%': { transform: 'translateY(0)' },
                  },
                }}
                endIcon={<ArrowOutward/>}
              >
                Join Splash now
              </Button>

            </>
          )}
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ textAlign: 'left' }}>
          <Typography paragraph>{body}</Typography>
        </CardContent>
      </Collapse>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Edit the post here"}
          </DialogTitle>
          <DialogContent>
            <Box 
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
              }}
              noValidate
              autoComplete="off">
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                placeholder="Type description here"
                autoFocus="off"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <FormControl fullWidth sx={{ my:2 }}>
                <InputLabel variant="standard" sx={{ mt:1 }} htmlFor="uncontrolled-native">
                  Choose Picture
                </InputLabel>
                <input
                  type="file"
                  accept="image/*, .pdf"
                  onChange={(e) => handleFileSelect(e.target.files)}
                />
                {imageUrl && (
                  <div>
                    <img src={imageUrl} alt="Selected" style={{ maxWidth: '100%', marginTop: 35, height:200 }} />
                  </div>
                )}
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} size='small'>Cancel</Button>
            <Button variant='contained' size='small' onClick={handleEdit} autoFocus>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Grid>
  );
}
