import { MusicNote } from '@mui/icons-material'
import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

function StatsCard({count,label}) {
  return (
    <Grid item xs={12} sm={3}>
        <Card sx={{textAlign:'center'}}>
            <CardContent>
            <Typography variant='h4' fontWeight={800}>{count}</Typography>
            <Typography variant='paragraph' sx={{color:'text.secondary'}}>{label}</Typography>
            </CardContent>
        </Card>
    </Grid>
  )
}

export default StatsCard