import { Button, Card, CardActions, CardHeader, Container, FormControl, Grid, InputLabel, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import PostCard from './PostCard';
import { Add, ArrowRightAlt } from '@mui/icons-material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRealm } from '../RealmProvider';

function AdminPosts({ home }) {
  const { user } = useRealm();
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setImageUrl('');
  };

  const handleFileSelect = (files) => {
    const file = files[0];
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const fetchPosts = async () => {
    setLoading(true);
    try {
      const mongo = user.mongoClient("mongodb-atlas");
      const collection = mongo.db("production").collection("posts");
      const posts = await collection.find({});
      setPosts(posts);
    } catch (error) {
      console.error("Failed to fetch posts:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleSubmit = () => {
    const postData = {
      description: description,
      file: selectedFile, // Assuming your API expects an image file
    };
    // dispatch(addNewPost(postData,token)).then(() => {
    //   handleClose();
    //   dispatch(fetchPosts());
    // });
  };

  const handleEdit = (postId, updatedPostData) => {
    // dispatch(updateExistingPost(postId, updatedPostData,token)).then(() => {
    //   dispatch(fetchPosts());
    // });
  };

  const handleDelete = (postId) => {
    // dispatch(removeExistingPost(postId,token)).then(() => {
    //   dispatch(fetchPosts());
    // });
  };

  useEffect(() => {
    fetchPosts();
  }, []);

  return (
    <Card sx={{ my: 10 }}>
      <CardHeader
        title={<Typography variant="h6" fontWeight={800}>Uploaded Posts</Typography>}
        action={
          <Button variant="contained" startIcon={<Add />} onClick={handleClickOpen} size="small">
            New Post
          </Button>
        }
      />
      {home ? (
        <Grid container spacing={1} sx={{ p: 1 }}>
          {posts?.slice(0, 3).map((post) => (
            <PostCard key={post.id} admin={true} data={post} onDelete={handleDelete} onEdit={handleEdit} />
          ))}
        </Grid>
      ) : (
        <Container>
          <Grid container spacing={1} sx={{ p: 1 }}>
            {posts?.map((post) => (
              <PostCard key={post.id} admin={true} data={post} onDelete={handleDelete} onEdit={handleEdit} />
            ))}
          </Grid>
        </Container>
      )}
      {home ? (
        <CardActions>
          <Button sx={{ width: '100%' }} variant="contained" onClick={() => navigate('/Posts/234')} endIcon={<ArrowRightAlt />}>
            See all posts
          </Button>
        </CardActions>
      ) : null}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Create a post here"}</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              placeholder="Type description here"
              autoFocus="off"
              value={description}
              onChange={handleDescriptionChange}
            />
            <FormControl fullWidth sx={{ my: 2 }}>
              <InputLabel variant="standard" sx={{ mt: 1 }} htmlFor="uncontrolled-native">
                Choose Picture
              </InputLabel>
              <input
                type="file"
                accept="image/*, .pdf" // Specify accepted file types
                onChange={(e) => handleFileSelect(e.target.files)}
              />
              {/* Display selected image */}
              {imageUrl && (
                <div>
                  <img src={imageUrl} alt="Selected" style={{ maxWidth: '100%', marginTop: 35, height: 200 }} />
                </div>
              )}
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="small">
            Cancel
          </Button>
          <Button variant="contained" size="small" onClick={handleSubmit} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default AdminPosts;
