import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useRealm } from '../RealmProvider';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { Alert, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../assets/images/favico.png'
import { loginSuccess } from '../redux/actions/authActions';
const bcrypt = require('bcryptjs')


export default function SignIn() {
  const [open, setOpen] = React.useState(false);
  const { user } = useRealm();
  const navigate = useNavigate()
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    
  };

  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal} = state;

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password')
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db("production").collection("users");

    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      // Check if the email is valid
      if (!emailRegex.test(email)) {
        setMessage("Please enter a valid email address.")
        setSeverity("error")
        setOpen(true)
        return;
      }
      
    try {
      setLoading(true)
      const user = await collection.findOne({ email: email });
      console.log(user);
      if (user!==null) {
        let currentUser = {
          "firstName": user.name,
          "lastName": user.surname,
          "email": user.email,
          "phoneNumber": "",
          "role": user.role
        }
        if (user && (await bcrypt.compare(password, user.password))) {
          if(user.role==="admin"){
            setLoading(false)
            dispatch(loginSuccess(currentUser))
          }
          if(isAuthenticated){
            navigate('/Dashboard/3098983')
          }
        }
        else{
          setLoading(false)
          setMessage("Incorrect password");
          setSeverity("error");
          setOpen(true);
          return;
        }
      }
      else{
        setLoading(false)
        setMessage("User not found");
        setSeverity("error");
        setOpen(true);
        return;
      }
    } catch (error) {
      setLoading(false)
      setMessage(error);
      setSeverity("error");
      setOpen(true);
      return;
    }
  };

  return (
      <Container component="main" maxWidth="xs">
      <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1 }} src={logo}/>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            {loading?
            <Button
              type="submit"
              fullWidth
              disabled
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              Loading...
            </Button>:
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>}
          </Box>
        </Box>
          <Snackbar
            open={open}
            autoHideDuration={5000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
            position="center"
          >
          <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
          </Snackbar>
      </Container>
  );
}