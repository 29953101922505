import React from "react";
import logo from "../assets/images/favico.png";

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading-content">
        <img src={logo} alt="logo" />
        <span className="loading-circle" />
        <span className="loading-circle" />
        <span className="loading-circle" />
      </div>
    </div>
  );
};

export default Loading;
