import { Container, Divider, Grid, Typography } from '@mui/material'
import React,{useState, useEffect} from 'react'
import StatsCard from '../components/StatsCard'
import ServiceProviders from '../components/ServiceProviders'
import AdminPosts from '../components/AdminPosts'
import { baseURL } from '../constants'


function Dashboard() {
  // State to store counts fetched from the API
  const [counts, setCounts] = useState({
    beauticians: 0,
    musicians: 0,
    actors: 0,
    productSellers: 0
  });

  // Function to fetch counts from the API
  const fetchCounts = async () => {
    try {
      const response = await fetch(`${baseURL}/get-counts`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      // Assuming the API response has properties for each count
      setCounts({
        beauticians: data?.counts?.beauty || 0,
        musicians: data?.counts?.music || 0,
        actors: data?.counts?.acting || 0,
        productSellers: data?.counts?.product_pelling || 0
      });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // useEffect hook to fetch counts when the component mounts
  useEffect(() => {
    fetchCounts();
  }, []); 

  return (
    <Container sx={{mt:15}}>
        <Typography variant='h6' fontWeight={800}>Management Dashboard</Typography>
        <Divider sx={{my:2}}/>
        <Grid container spacing={1}>
            <StatsCard count={counts?.beauticians} label={"Beauticians"}/>
            <StatsCard count={counts?.musicians} label={"Musicians"}/>
            <StatsCard count={counts?.actors} label={"Actors"}/>
            <StatsCard count={counts?.productSellers} label={"Product Sellers"}/>
        </Grid>
        <AdminPosts home={true}/>
        <ServiceProviders/>
    </Container>
  )
}

export default Dashboard