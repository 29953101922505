import React, { createContext, useContext, useEffect, useState } from 'react';
import * as Realm from "realm-web";
import atlasConfig from "./atlasConfig.json"

const { baseUrl } = atlasConfig;
// Create a new Realm app instance
const app = new Realm.App({ id: "application-0-sijwiwd",baseUrl });

const RealmContext = createContext();

export const RealmProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const login = async () => {
      try {
        console.log("Attempting to log in...");
        const credentials = Realm.Credentials.anonymous();
        const user = await app.logIn(credentials);
        console.log("Logged in as user:", user.id);
        setUser(user);
      } catch (err) {
        console.error("Failed to log in:", err);
      }
    };

    login();
  }, []);

  return (
    <RealmContext.Provider value={{ user }}>
      {children}
    </RealmContext.Provider>
  );
};

export const useRealm = () => {
  return useContext(RealmContext);
};
