import React,{useEffect,useState} from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Card, CardHeader, Divider } from '@mui/material';
import { useRealm } from '../RealmProvider';

const columns = [
  { field: 'id', headerName: 'ID', width: 90 },
  {
    field: 'name',
    headerName: 'Name',
    width: 160,
    editable: false,
  },
  {
    field: 'surname',
    headerName: 'Surname',
    width: 160,
    editable: false,
  },
  {
    field: 'email',
    headerName: 'Email',
    width: 300,
    editable: false,
  },
  {
    field: 'role',
    headerName: 'Category',
    sortable: true,
    width: 160
  },
];


export default function ServiceProviders() {
  const { user } = useRealm();
  const [users,setUsers] = useState([]);
  

    // Function to fetch counts from the API
    const fetchUsers = async () => {
      const mongo = user.mongoClient("mongodb-atlas");
      const collection = mongo.db("production").collection("users");
      try {
        const posts = await collection.find({});
        console.log(posts);
        const usersWithId = posts?.map((user, index) => ({ ...user, id: index + 1 }));
        setUsers(usersWithId);        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    // useEffect hook to fetch counts when the component mounts
    useEffect(() => {
      fetchUsers();
    }, []); 
  return (
    <Card sx={{  width: '100%', mt:3 }}>
        <CardHeader title="Service Providers"/>
        <Divider/>
     <Box sx={{ height: 400}}>
      <DataGrid
        rows={users}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
      </Box>
    </Card>
  );
}