import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    REGISTER_REQUEST,
    REGISTER_SUCCESS,
    REGISTER_FAILURE,
    LOGOUT_USER_REQUEST,
    LOGOUT_USER_SUCCESS,
    LOGOUT_USER_FAILURE,
    GET_LOGGEDIN_USER_REQUEST,
    GET_LOGGEDIN_USER_SUCCESS,
    GET_LOGGEDIN_USER_FAILURE,
  } from '../actions/authActions';
  
  const initialState = {
    loading: false,
    user: null,
    error: null,
    isAuthenticated: false,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case LOGIN_REQUEST:
      case REGISTER_REQUEST:
      case LOGOUT_USER_REQUEST:
      case GET_LOGGEDIN_USER_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        };
  
      case LOGIN_SUCCESS: 
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        error: null,
      };    
      case REGISTER_SUCCESS:
        return {
          ...state,
          isAuthenticated: false,
          loading: false,
          user: action.payload,
          error: null,
        };
  
      case GET_LOGGEDIN_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
          error: null,
        };
      case LOGIN_FAILURE:
      case REGISTER_FAILURE:
      case LOGOUT_USER_FAILURE:
      case GET_LOGGEDIN_USER_FAILURE:
        return {
          ...state,
          isAuthenticated: false,
          loading: false,
          user: null,
          error: action.payload,
        };
      case LOGOUT_USER_SUCCESS:
        return {
          ...state,
          isAuthenticated: false,
          user: null,
          loading: false,
          error: null,
        };
  
      default:
        return state;
    }
  };
  
  export default authReducer;
  
    