import { createSlice } from '@reduxjs/toolkit';

const postSlice = createSlice({
  name: 'posts',
  initialState: {
    posts: null,
    error: null,
    loading: false
  },
  reducers: {
    setPosts: (state, action) => {
      state.posts = action.payload;
      state.error = null;
      state.loading = false;
    },

    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.loading = false;
    },

    sendingRequest: (state, action)=>{
      state.loading = true;
    },

    addPost: (state, action) => {
      state.posts.push(action.payload);
    },

    removePost: (state, action) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
    },

    updatePost: (state, action) => {
      const { id, updatedPostData } = action.payload;
      const postToUpdate = state.posts.find((post) => post.id === id);
      if (postToUpdate) {
        Object.assign(postToUpdate, updatedPostData);
      }
    },
  },
});

export default postSlice.reducer;
export const { setPosts, setError, addPost, removePost, updatePost,sendingRequest} = postSlice.actions;
