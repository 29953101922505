import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import cciga from '../assets/images/cciga.jpeg';


const whiteLogos = [
    cciga
];


const logoStyle = {
  width: '150px',
  height: '60px',
  margin: '0 12px',
  borderRadius:15
};

export default function LogoCollection() {
  const logos =  whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="h6"
        align="center"
        color="inherit"
        
      >
        Trusted by the best organisations:
      </Typography>
      <Grid container justifyContent="center" sx={{ mt: 0.5, opacity: 0.9 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}