import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {  Alert, MenuItem } from '@mui/material';
import 'react-phone-number-input/style.css';
import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import validator from 'validator';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { useRealm } from '../RealmProvider';
import logo from '../assets/images/favico.png';
const bcrypt = require('bcryptjs')

const categories = [
  {
    value: 'beauty',
    label: 'Beauty',
  },
  {
    value: 'product_pelling',
    label: 'Product Selling',
  },
  {
    value: 'acting',
    label: 'Acting',
  },
  {
    value: 'music',
    label: 'Music',
  },
];
export default function SignUp() {
  const [open, setOpen] = React.useState(false);
  const { user } = useRealm();
  const navigate = useNavigate()
  const [loading, setLoading] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const [message, setMessage] = React.useState("");
  const [severity, setSeverity] = React.useState("");

  const [state, setState] = React.useState({
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal} = state;

  const [formData, setFormData] = useState({phoneNumber: '',role: '',name: '',surname: '',email: '',password: ''});

  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = React.useState(false);

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const mongo = user.mongoClient("mongodb-atlas");
    const collection = mongo.db("production").collection("users");

    let newErrors = {};
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = 'Phone number is required';
    } else if (!validator.isMobilePhone(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Invalid phone number';
    }
    if (!formData.role) {
      newErrors.role = 'Service is required';
    }
    if (!formData.name) {
      newErrors.name = 'Name is required';
    }
    if (!formData.surname) {
      newErrors.surname = 'Surname is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!validator.isEmail(formData.email)) {
      newErrors.email = 'Invalid email';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters long';
    }

    if (Object.keys(newErrors).length === 0) {
      setLoading(true)
      console.log('Form submitted:', formData);
      // Submit form data or proceed further
      
    try {       
      const salt = await  bcrypt.genSalt(10);
      formData.password = await bcrypt.hash(formData.password,salt);
      const user = await collection.insertOne(formData);
      if (user) {
        console.log(user);
        setLoading(false) 
        setMessage('You have successfully created a new account');
        setSeverity("success");
        setOpen(true);
        setFormData({phoneNumber: '',role: '',name: '',surname: '',email: '',password: ''})
        
      }
      else{
        setLoading(false) 
        setMessage("Could not register your account");
        setSeverity("error");
        setOpen(true);
        return;
      }
    }
    catch(error){
      setLoading(false) 
      setMessage(error);
      setSeverity("error");
      setOpen(true);
      return;

    }
    } else {
      setErrors(newErrors);
      return;
    }
  };

  return (
      <Container component="main" maxWidth="sm">
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
        <Avatar sx={{ m: 1 }} src={logo}/>
          <Typography component="h1" variant="h5">
            Sign up
          </Typography>
          <Typography variant="body2">
            To become a service provider on SPLASH select either Beauty or Product Selling category. To learn more about other platforms and categories, 
            <Button variant='text' size='small' color='primary' onClick={()=>navigate('/Info')}>click here!</Button>
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="surname"
                  value={formData.surname}
                  onChange={handleChange}
                  error={!!errors.surname}
                  helperText={errors.surname}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Phone Number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
              <TextField
                id="outlined-select-currency"
                fullWidth
                required
                select
                label="Select Category"
                defaultValue="beauty"
                name="role"      
                value={formData.role}
                onChange={handleChange}
                sx={{height:40}}
              >
                {categories.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              </Grid>
            </Grid>
            {loading?
            <Button
              type="submit"
              disabled
              variant="outlined"
              sx={{ mt: 3, mb: 2 }}
            >
              Loading...
            </Button>:
            <Button
              type="submit"
              
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign Up
            </Button>}
          </Box>
        </Box>
          <Snackbar
            open={open}
            autoHideDuration={5000}
            anchorOrigin={{ vertical, horizontal }}
            onClose={handleClose}
            position="center"
          >
          <Alert
            onClose={handleClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
          </Snackbar>
      </Container>
  );
}