import axios from 'axios';
import { baseURL } from '../../constants';

// Action types
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';


export const LOGOUT_USER_REQUEST = 'LOGOUT_USER_REQUEST';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAILURE = 'LOGOUT_USER_FAILURE';

export const GET_LOGGEDIN_USER_REQUEST = 'GET_LOGGEDIN_USER_REQUEST';
export const GET_LOGGEDIN_USER_SUCCESS = 'GET_LOGGEDIN_USER_SUCCESS';
export const GET_LOGGEDIN_USER_FAILURE = 'GET_LOGGEDIN_USER_FAILURE';

// Login action creators
export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const loginFailure = (error) => {
  return {
    type: LOGIN_FAILURE,
    payload: error,
  };
};

// Register action creators
export const registerRequest = () => {
  return {
    type: REGISTER_REQUEST,
  };
};

export const registerSuccess = (user) => {
  return {
    type: REGISTER_SUCCESS,
    payload: user,
  };
};

export const registerFailure = (error) => {
  return {
    type: REGISTER_FAILURE,
    payload: error,
  };
};

export const register = (data) => {
  return (dispatch) => {
    dispatch(registerRequest());

    axios
      .post(`${baseURL}/users/create-user`, data)
      .then((response) => {
        // Assuming the API response contains the user data
        const user = response.data;
        console.log("Register success. User data:", user);
        dispatch(registerSuccess(user));
      })
      .catch((error) => {
        dispatch(registerFailure(error.response.data.error));
      });
  };
};

export const logoutUser = () => {
  return { type: LOGOUT_USER_SUCCESS };
};

export const getLoggedInUser = () => {
  return { type: GET_LOGGEDIN_USER_SUCCESS }
};