import './App.css';
import * as React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {useLocation } from "react-router-dom";
import { Box, Container, Divider } from '@mui/material';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import { ThemeProvider, createTheme, styled,keyframes } from '@mui/material/styles';
import AppAppBar from './components/APPAPPBar';
import getLPTheme from './pages/getLPTheme';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './components/Footer';
import Info from './pages/Info';
import Dashboard from './pages/Dashboard';
import AdminPosts from './components/AdminPosts';
import { useDispatch, useSelector } from 'react-redux';
import ErrorPage from './pages/ErrorPage';

const defaultTheme = createTheme({});

function App() {
  const [mode, setMode] = React.useState('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log("is auth?",isAuthenticated);
  const LPtheme = createTheme(getLPTheme(mode));

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };
  
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  
  return (
    <div className="App">
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Box>
        <ScrollToTop />
        <Routes>
           <Route  exact path="/" element={<Home/>}/>
           <Route  exact path="/SignIn" element={<SignIn/>}/>
           <Route  exact path="/SignUp" element={<SignUp/>}/>
           <Route  exact path="/Info" element={<Info/>}/>
            {isAuthenticated?<Route  exact path="/Dashboard/:id" element={<Dashboard/>}/>:null}
           <Route  exact path="/Posts/:id" element={<AdminPosts/>}/>
            <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </Box>
      <Container>
      <Divider sx={{mt:3}}/>
      </Container>
      <Footer/>
    </ThemeProvider>
    </div>
  );
}

export default App;
