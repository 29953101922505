import { Button, Card, CardActions, CardContent, CardHeader, Container, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function ErrorPage() {
    const navigate = useNavigate();
  return (
    <Container sx={{mt:18}}>
        <Card style={{textAlign:'center'}}>
            <CardHeader title={<Typography variant='h1'>404</Typography>} subheader={<Typography variant='h5'>ERROR!!</Typography>}/>
        <CardContent>
            <Typography variant='paragraph'>Oops....</Typography>
            <Typography variant='paragraph'>The page that you are looking for is not found</Typography>
        </CardContent>
        <CardActions>
            <Button onClick={()=>navigate('/')} variant='contained'>Back to home</Button>
        </CardActions>
        </Card>
    </Container>
  )
}

export default ErrorPage