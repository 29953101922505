import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/Frame (1).png'

export default function Hero() {
  const navigate = useNavigate()


  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'linear-gradient(#02294F, #090E10)',
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            component="h1"
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
            }}
          >
            Our latest&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              projects
            </Typography>
          </Typography>
          <Typography variant="body2" textAlign="center" color="text.secondary">
            Explore our cutting-edge products tailored to help different service providers showcase their skills and offerings to a wide audience. <br />
            Elevate your experience with top-tier features and services.
          </Typography>
           <img src={logo} style={{width:'100px', alignSelf:'center'}}/>
          <Typography variant="title2" textAlign="center" fontWeight={800} color="error" sx={{fontStyle:"italic"}}>
           🚀Launching Splash Soon!🚀
          </Typography> 
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            <Button variant="contained" onClick={()=>navigate('/SignUp')} color="primary">
              Get Started
            </Button>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
}