import * as React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Hero from '../components/Hero';
import LogoCollection from '../components/LogoCollection';
import Posts from '../components/Posts';

export default function Home() {
  return (
    <Box>
      <Hero />
      <Divider/>
      <Box sx={{ bgcolor: 'background.default' }}>
        <Posts/>
        <LogoCollection />
      </Box>
    </Box>
  );
}